import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';

/**
 * TOPページのMVにて使用
 */
export function slideTopHero() {
  const slideTopHero = new Swiper('.js-slideTopHero', {
    modules: [Autoplay, Pagination, EffectFade],
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.js-slideTopHeroPager',
    },
  });
}

/**
 * TOPページのMV下にて使用
 */
export function slideSpecial() {
  const slideSpecial = new Swiper('.js-slideSpecial', {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1.1,
    spaceBetween: 20,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: '.js-slideSpecialNext',
      prevEl: '.js-slideSpecialPrev',
    },
    breakpoints: {
      768: {
        centeredSlides: true,
        slidesPerView: 3,
        spaceBetween: 32,
      },
      1025: {
        centeredSlides: true,
        slidesPerView: 4.1,
        spaceBetween: 56,
      },
    },
  });
}

/**
 * 特集スライダー
 */
export function slideFeature() {
  const slideSpecial = new Swiper('.js-slideFeature', {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    autoHeight: true,
    spaceBetween: 20,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: '.js-slideFeatureNext',
      prevEl: '.js-slideFeaturePrev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  });
}

export function slideFeature02() {
  const slideSpecial = new Swiper('.js-slideFeature02', {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    autoHeight: true,
    spaceBetween: 20,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    navigation: {
      nextEl: '.js-slideFeature02Next',
      prevEl: '.js-slideFeature02Prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  });
}
