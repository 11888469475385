export function accordion() {
  document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.js-acDetails')) {
      setUpAccordion();
    }
  });

  /**
   * ブラウザの標準機能(Web Animations API)を使ってアコーディオンのアニメーションを制御します
   */
  const setUpAccordion = () => {
    const details = document.querySelectorAll('.js-acDetails');
    const RUNNING_VALUE = 'running'; // アニメーション実行中のときに付与する予定のカスタムデータ属性の値
    const IS_OPENED_CLASS = 'is-opened'; // アイコン操作用のクラス名

    details.forEach((element) => {
      const summary = element.querySelector('.js-acSummary');
      const content = element.querySelector('.js-acCont');
      if (!summary) {
        return false;
      }
      summary.addEventListener('click', (event) => {
        // デフォルトの挙動を無効化
        event.preventDefault();

        // 連打防止用。アニメーション中だったらクリックイベントを受け付けないでリターンする
        if (element.dataset.animStatus === RUNNING_VALUE) {
          return;
        }

        // detailsのopen属性を判定
        if (element.open) {
          // アコーディオンを閉じるときの処理
          // アイコン操作用クラスを切り替える(クラスを取り除く)
          element.classList.toggle(IS_OPENED_CLASS);

          // アニメーションを実行
          const closingAnim = content.animate(closingAnimKeyframes(content), animTiming);
          // アニメーション実行中用の値を付与
          element.dataset.animStatus = RUNNING_VALUE;

          // アニメーションの完了後に
          closingAnim.onfinish = () => {
            // open属性を取り除く
            element.removeAttribute('open');
            // アニメーション実行中用の値を取り除く
            element.dataset.animStatus = '';
          };
        } else {
          // アコーディオンを開くときの処理
          // open属性を付与
          element.setAttribute('open', 'true');

          // アイコン操作用クラスを切り替える(クラスを付与)
          element.classList.toggle(IS_OPENED_CLASS);

          // アニメーションを実行
          const openingAnim = content.animate(openingAnimKeyframes(content), animTiming);
          // アニメーション実行中用の値を入れる
          element.dataset.animStatus = RUNNING_VALUE;

          // アニメーション完了後にアニメーション実行中用の値を取り除く
          openingAnim.onfinish = () => {
            element.dataset.animStatus = '';
          };
        }
      });
    });
  };

  /**
   * アニメーションの時間とイージング
   */
  const animTiming = {
    duration: 200,
    easing: 'ease-out',
  };

  /**
   * アコーディオンを閉じるときのキーフレーム
   */
  const closingAnimKeyframes = (content) => [
    {
      height: content.offsetHeight + 'px', // height: "auto"だとうまく計算されないため要素の高さを指定する
      opacity: 1,
    },
    {
      height: 0,
      opacity: 0,
    },
  ];

  /**
   * アコーディオンを開くときのキーフレーム
   */
  const openingAnimKeyframes = (content) => [
    {
      height: 0,
      opacity: 0,
    },
    {
      height: content.offsetHeight + 'px',
      opacity: 1,
    },
  ];
}

export function accordionCsr() {
  const elements = document.querySelectorAll('.js-more');
  const IS_OPEN = 'is-open';

  Array.from(elements).forEach(function (el) {
    //ボタンを取得
    const btn = el.querySelector('.js-moreBtn');
    //コンテンツを取得
    const content = el.querySelector('.js-moreCont');

    //ボタンクリックでイベント発火
    btn.addEventListener('click', function () {
      const thisBtnData = btn.dataset.lang;

      if (!content.classList.contains(IS_OPEN)) {
        //コンテンツの実際の高さを代入
        //キーワード値（none、max-content等）では動作しないので注意
        content.style.maxHeight = content.scrollHeight + 'px';
        //openクラスを追加
        content.classList.add(IS_OPEN);
        btn.classList.add(IS_OPEN);
        //もっと見るボタンのテキストを設定
        if (thisBtnData == 'jp') {
          btn.textContent = '閉じる';
        } else {
          btn.textContent = 'Close';
        }
      } else {
        //コンテンツの高さを固定値を代入
        content.style.maxHeight = '15rem';
        //openクラスを削除
        content.classList.remove(IS_OPEN);
        btn.classList.remove(IS_OPEN);
        //もっと見るボタンのテキストを設定
        if (thisBtnData == 'jp') {
          btn.textContent = 'もっと見る';
        } else {
          btn.textContent = 'Read All';
        }
      }
    });
  });
}

export function accordionReport() {
  document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.js-acDetails')) {
      setUpAccordion();
    }
  });

  /**
   * ブラウザの標準機能(Web Animations API)を使ってアコーディオンのアニメーションを制御します
   */
  const setUpAccordion = () => {
    const details = document.querySelectorAll('.js-acDetails');
    const acBtnEle = document.querySelector('.js-acBtn');
    const RUNNING_VALUE = 'running'; // アニメーション実行中のときに付与する予定のカスタムデータ属性の値
    const IS_OPEN_CLASS = 'is-open'; // アイコン操作用のクラス名

    details.forEach((element) => {
      const summary = element.querySelector('.js-acSummary');
      const content = element.querySelector('.js-acCont');

      if (element.querySelector('.js-acSummary')) {
        summary.addEventListener('click', (event) => {
          // デフォルトの挙動を無効化
          event.preventDefault();

          // 連打防止用。アニメーション中だったらクリックイベントを受け付けないでリターンする
          if (element.dataset.animStatus === RUNNING_VALUE) {
            return;
          }

          // detailsのopen属性を判定
          if (element.open) {
            // アコーディオンを閉じるときの処理
            // アイコン操作用クラスを切り替える(クラスを取り除く)
            element.classList.toggle(IS_OPEN_CLASS);
            acBtnEle.classList.toggle(IS_OPEN_CLASS);

            acBtnEle.innerText = 'もっと見る';

            // アニメーションを実行
            const closingAnim = content.animate(closingAnimKeyframes(content), animTiming);
            // アニメーション実行中用の値を付与
            element.dataset.animStatus = RUNNING_VALUE;

            // アニメーションの完了後に
            closingAnim.onfinish = () => {
              // open属性を取り除く
              element.removeAttribute('open');
              // アニメーション実行中用の値を取り除く
              element.dataset.animStatus = '';
            };
          } else {
            // アコーディオンを開くときの処理
            // open属性を付与
            element.setAttribute('open', 'true');

            // アイコン操作用クラスを切り替える(クラスを付与)
            element.classList.toggle(IS_OPEN_CLASS);
            acBtnEle.classList.toggle(IS_OPEN_CLASS);

            acBtnEle.innerText = '閉じる';

            // アニメーションを実行
            const openingAnim = content.animate(openingAnimKeyframes(content), animTiming);
            // アニメーション実行中用の値を入れる
            element.dataset.animStatus = RUNNING_VALUE;

            // アニメーション完了後にアニメーション実行中用の値を取り除く
            openingAnim.onfinish = () => {
              element.dataset.animStatus = '';
            };
          }
        });
      }
    });
  };

  /**
   * アニメーションの時間とイージング
   */
  const animTiming = {
    duration: 400,
    easing: 'ease-out',
  };

  /**
   * アコーディオンを閉じるときのキーフレーム
   */
  const closingAnimKeyframes = (content) => [
    {
      height: content.offsetHeight + 'px', // height: "auto"だとうまく計算されないため要素の高さを指定する
      opacity: 1,
    },
    {
      height: 0,
      opacity: 0,
    },
  ];

  /**
   * アコーディオンを開くときのキーフレーム
   */
  const openingAnimKeyframes = (content) => [
    {
      height: 0,
      opacity: 0,
    },
    {
      height: content.offsetHeight + 'px',
      opacity: 1,
    },
  ];
}
