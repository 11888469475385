export function modalMovie() {
  if (document.querySelector('.js-modal')) {
    const body = document.querySelector('body');
    const modal = document.querySelector('.js-modal');
    const modalTrigger = document.querySelectorAll('.js-modalTrigger');
    const triggerList = Array.prototype.slice.call(modalTrigger, 0);
    const modalOverlay = document.querySelector('.js-modalOverlay');
    const modalClose = document.querySelector('.js-modalClose');

    const IS_FIXED = 'is-fixed';
    const IS_ACTIVE = 'is-active';

    /** modal open */
    triggerList.forEach((targetBox) => {
      targetBox.addEventListener('click', () => {
        const movieId = targetBox.getAttribute('data-movie');

        const movieCont = document.createElement('div');

        movieCont.className = 'c-modal_cont';

        movieCont.innerHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + movieId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

        modal.appendChild(movieCont);

        body.classList.add(IS_FIXED);
        modalOverlay.classList.add(IS_ACTIVE);
        modal.classList.add(IS_ACTIVE);
      });
    });

    /** modal close */
    modalClose.addEventListener('click', () => {
      const modalMovie = document.querySelector('.c-modal_cont');
      modal.removeChild(modalMovie);

      body.classList.remove(IS_FIXED);
      modalOverlay.classList.remove(IS_ACTIVE);
      modal.classList.remove(IS_ACTIVE);
    });
  }
}
