import Aos from 'aos';

export function aosConfig() {
  window.addEventListener('load', () => {
    Aos.init({
      offset: 50,
      duration: 600,
      once: true,
    });
  });
}
