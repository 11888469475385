import { aosConfig } from './modules/aosConfig';
import { anchorSmoothMove } from './modules/anchorSmoothLink';
import { accordion } from './modules/accordion';
import { headerConfig } from './modules/headerConfig';
import { indexCompanyImg } from './modules/indexCompanyImg';
import { panelSlide } from './modules/panelSlide';
import { scrollmagic } from './modules/scrollmagic';
import { slideSpecial, slideTopHero, slideFeature, slideFeature02 } from './modules/swiperConfig';
import { headerActiveControl } from './modules/windowAction';
import { modalMovie } from './modules/modalMovie';

// swiper
panelSlide();

anchorSmoothMove();

slideSpecial();
slideTopHero();
slideFeature();
slideFeature02();

headerConfig();
accordion();

indexCompanyImg();

scrollmagic();
headerActiveControl();

aosConfig();

modalMovie();
