export function headerConfig() {
  const IS_ACTIVE = 'is-active';
  const IS_VIEW = 'is-view';
  const IS_FIXED = 'is-fixed';
  const IS_OPENED = 'is-opened';

  headerMegaUiControl();
  hamburgerControl();

  /**
   * ハンバーガー開閉処理
   */
  function hamburgerControl() {
    const bodyEle = document.querySelector('body');
    const headerHamburgerEle = document.querySelector('.js-headerHamburger');
    const headerMenuEle = document.querySelector('.js-headerMenu');

    headerHamburgerEle.addEventListener('click', () => {
      headerHamburgerEle.classList.toggle(IS_ACTIVE);
      bodyEle.classList.toggle(IS_FIXED);
      headerMenuEle.classList.toggle(IS_VIEW);
      headerMenuEle.classList.add(IS_OPENED);
    });
  }

  /**
   * メガメニュー表示・非表示処理
   */
  function headerMegaUiControl() {
    const headerMegaTriggerAll = document.querySelectorAll('.js-headerMegaTrigger');

    // メガメニュー表示
    headerMegaTriggerAll.forEach((target) => {
      target.addEventListener('mouseover', () => {
        const thisActiveMenu = target.querySelector('.js-headerMega');

        target.classList.add(IS_ACTIVE);
        thisActiveMenu.classList.add(IS_VIEW);
      });
    });

    // メガメニュー非表示
    headerMegaTriggerAll.forEach((target) => {
      target.addEventListener('mouseleave', () => {
        const thisActiveMenu = target.querySelector('.js-headerMega');

        target.classList.remove(IS_ACTIVE);
        thisActiveMenu.classList.remove(IS_VIEW);
      });
    });
  }
}
