import SmoothScroll from 'smooth-scroll';

export function anchorSmoothMove() {
  window.addEventListener('DOMContentLoaded', () => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      const scroll = new SmoothScroll('a[href*="#"]', {
        header: '#header',
        speed: 300,
        speedAsDuration: true,
        updateURL: false,
      });
    } else if (window.matchMedia('(min-width:768px)').matches) {
      const scroll = new SmoothScroll('a[href*="#"]', {
        speed: 300,
        speedAsDuration: true,
        updateURL: false,
      });
    }
  });
}
