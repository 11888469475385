import { throttle } from 'lodash';

export function headerActiveControl() {
  const IS_ANIMATED = 'is-animated';
  const IS_ACTIVE = 'is-active';

  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        headerActive();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );

  // ページ読み込み時にスクロールトップボタンがkvより下にあれば表示
  window.addEventListener('load', () => {
    headerActive;
  });

  // 間引きしたい処理
  function headerActive() {
    if (document.querySelector('.js-headerTrigger')) {
      const headerTrigger = document.querySelector('.js-headerTrigger');
      const headerEle = document.getElementById('header');

      if (headerTrigger.classList.contains(IS_ANIMATED)) {
        headerEle.classList.add(IS_ACTIVE);
      } else {
        headerEle.classList.remove(IS_ACTIVE);
      }
    }
  }
}
