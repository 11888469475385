import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function panelSlide() {
  if (document.querySelector('.js-panelSlide')) {
    const listWrapperEl = document.querySelector('.js-panelSlide');
    const listEl = document.querySelector('.js-panelSlideList');

    gsap.to(listEl, {
      x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-panelSlideTrigger',
        start: 'top top', // 要素の上端（top）が、ビューポートの上端（top）にきた時
        end: () => `+=${listEl.clientWidth * 2 - listWrapperEl.clientWidth}`,
        scrub: true,
        pin: true,
        markers: false,
        anticipatePin: 0,
        invalidateOnRefresh: true,
      },
    });
  }
}
