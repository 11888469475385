export function indexCompanyImg() {
  if (document.querySelector('.js-indexCompanyImg')) {
    const indexCompanyTriggerAll = document.querySelectorAll('.js-indexCompanyTrigger');
    const indexCompanyImg = document.querySelector('.js-indexCompanyImg');

    indexCompanyTriggerAll.forEach((target) => {
      target.addEventListener('mouseover', () => {
        const targetData = target.dataset.trigger;

        indexCompanyImg.dataset.num = targetData;
      });
    });
  }
}
